@import "../../styles/variables";

.tabs {
  list-style: none;
  margin: 0 -17px 0 -17px;
  padding: 0;
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  overflow: auto;
}

.item {
  color: $font-grey;
  list-style: none;
  margin: 0;
  font-size: 13px;
  padding: 0 17px;
  display: block;
  position: relative;
  font-weight: 600;
  white-space: nowrap;

  a {
    display: block;
    padding: 10px 0;
    color: inherit;
    border-bottom:  5px solid transparent;
    text-transform: uppercase;

    &:hover, &:focus {
      color: inherit;
      border-bottom-color: rgba(0, 0, 0, .1);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    border-right: 1px solid $hover-grey;
    height: 17px;
  }

  &:last-child:after {
    display: none;
  }
}

.active {
  font-weight: bold;

  a {
    border-bottom-color: $primary-color;

    &:hover, &:focus {
      border-bottom-color: $primary-color;
    }
  }
}
