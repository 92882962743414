@import '../../styles/variables.scss';

.wrapper {
  &:global(.modal.ui) {

    @media only screen and (min-width: 1200px) {
      width: 750px;
    }

    &:global(.page.modals.dimmer.visible.active) {
      background-color: white !important;
    }

    & > :global(.content) {
      background-color: #f1f4f7;
    }
    & > :global(.actions) {
      background-color: #f1f4f7;
      border-top: 0;
    }
    & > :global(.close) {
      color: #000000
    }
  }

  :global .ui.input input[type="text"],
  :global .ui.input input[type="date"],
  :global .ui.input input[type="time"],
  :global .ui.input input[type="number"],
  :global .ui.selection.dropdown {
    border: 0;
    height: 35px;
  }

  :global .s

  :global .field textarea {
    border: 0 !important;
  }

  :global .ui.form label,
  :global .ui.form .field label {
    color: $primary-color;
    font-weight: 600;
  }

  :global(.field).date {
    width: 28% !important;
  }

  :global(.field).time {
    width: 22% !important;
  }

  :global .error .ui.selection .default.text {
    color: #fbe6ea !important;
  }
}

.title {
  color: $font-grey;
  text-align: left;
  font-size: 16px;
  border-bottom: 1px solid $hover-grey;
  font-weight: normal;
  padding: 10px 0;
  margin-bottom: 20px;
}

.documentContainer {
  align-items: flex-end;
  display: flex;
  margin-bottom: 15px;
}

.documentNumberContainer {
  flex: 1;
  margin-left: 15px;
  width: 100%;
}

.documentBtnContainer {
  padding: 0 0 1px 15px;
  margin-top: auto;
  :global .ui.button {
    min-height: 36px !important;
  }
}

.birthDateContainer {
  display: flex;
  align-items: flex-end;
}

.birthDateDay {
  margin-right: 15px;
  width: 72px;
}

.birthDateMonth {
  margin-right: 15px;
  width: 120px;
}

.birthDateYear {
  align-items: center;
  display: flex;

  .birthDateYearInput {
    width: 90px;
  }

  .birthDateYearHelp {
    color: #a1a1a1;
    margin-left: 15px;
  }
}

.phoneRow {
  align-items: flex-end !important;
}

.fakeLabel {
  color: #224874;
  display: inline-block;
  font-weight: normal;
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.phoneContainer {
  align-items: flex-end;
  display: flex;
  margin-bottom: 15px;

  .phone {
    flex: 1;
    margin-right: 15px;
    width: 100%;
  }
}

.inputContainer {
  margin-bottom: 15px;
  height: 65px;
}

.select {
  margin-bottom: 15px;
  height: 65px;
}

.rootInput {
  height: 35px;
  margin-right: 25px;
}

.inputOferta {
  margin-bottom: 15px;
  height: 65px;
}

.emailSelectContainer {
  margin-bottom: 15px;
}

.selectContainer {
  display: flex;
}

.successIcon {
  color: #06b561;
  animation: fadeIn;
}

