@import "../../styles/variables.scss";

.ctasCarousel {
  display: flex;
  flex: 1 1 1;
  width: 100%;
  margin-right: 15px;
  background-color: transparentize(yellow, 0.8);
  border-radius: 15px;
  height: 160px;
  margin-bottom: 13px;
  position: relative;
  overflow: hidden;
  @include withShadows;
  @include withBorder;

  &.pausedItem {
    cursor: pointer;
  }

  .backgroundImage {
    border-radius: 15px;
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &.greyscaleItem,
    &.isCompleted,
    &.empty {
      background: $hover-grey;
    }
  }

  &:not(.empty):not(.isCompleted):hover {
    &::before {
      background-image: linear-gradient(to bottom, #1c5880, #3f407c);
    }
  }

  &.empty,
  &.isCompleted {
    &::before {
      background-image: linear-gradient(to bottom, #3b3c45, #05273b);
      opacity: 0.78;
    }
  }

  &.pausedItem {
    &::before {
      background-image: none;
      background-color: rgba(0, 0, 0, 0.70);
    }
  }

  &.borderedItem {
    &::after {
      border: 3px solid $primary-color;
      border-radius: 15px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 999;
      @include withShadows
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.unPause {
  align-items: center;
  color: $font-grey;
  cursor: pointer;
  display: flex;
  fill: #ffffff;
  font-weight: 600;
  left: 0;
  padding-top: 20px;
  padding-left: 20px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transition: color 200ms ease, fill 200ms fill;
  width: 100%;
  z-index: 1001;

  &:hover {
    color: #b4bdff;
    fill: #b4bdff;
    transition: color 200ms ease, fill 200ms fill;
  }

  svg {
    margin-left: 10px;
    fill: $primary-color;
  }
}

.ctaCarouselSelected {
  z-index: 999;
}

.ctaCarouselLabel {
  pointer-events: none;
  color: $primary-color;
  padding: 10px 20px;
  font-size: 14px;
  z-index: 3;
}

.ctaCarouselControls {
  align-items: center;
  color: $font-grey;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 5;

  .ctaCarouselRemaining {
    align-items: center;
    background: $primary-color;
    border-radius: 50%;
    display: flex;
    color: $white;
    font-weight: 500;
    justify-content: center;
    height: 28px;
    width: 28px;
  }

  .ctaCarouselActionIcon {
    cursor: pointer;
    fill: $font-grey;
    transition: fill 200ms ease;

    &:hover {
      fill: #979797;
      transition: fill 200ms ease;
    }
  }
}

.ctaCarouselItems {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.noCtasText {
  bottom: 20px;
  color: $font-grey;
  font-size: 12px;
  left: 20px;
  position: absolute;
  z-index: 900;
}
