$font-family-montserrat: 'Muli', sans-serif;

$default-font: $font-family-montserrat;

$main-navbar-height: 82px;
$main-navbar-logo-height: 40px;
$secondary-navbar-width: 63px;


/* backoffice */
$filter-bar-top: #e8ebf3;
$filter-bar-bg: #a0b5ca;
$main-color: #23426f;
$disabled: #cbd4dd;
$input-border: #b2b2b2;
/* backoffice */


$hola1: #5180C5;
$holas: #96CBF1;
$holax: #808183;

/* V2 */
$black: #000000;
$white: #fff;
$primary-color: #0170d4;
$primary-light: #5e9eff;
$primary-dark: #0046a2;
$secondary: #33517a;
$background: #f3f3f3;
$font-grey: #666666;
$hover-grey: #f3f3f3;
$light-grey: #d1d1d1;
$another-grey: #e0e0e0;

//STATUS COLORS
$error-color: #f44336;
$warning-yellow: #fdd835;
$warning-orange: #ff9800;
$success-color: #4BB543;

//SOCIAL
$whatsapp-color: #25d366;
$twitter-color: #35d5e4;
$facebook-color: #165182;

//ELEMENTS
$primary-button: $primary-color;
//navbars
$navbar: $white;
$secondary-navbar: $primary-color;
//search
$search-box: $font-grey;
$search-box-icon: $primary-dark;
$sistematic-gauges: $success-color;
//login
$login-bg: $white;
$login-button: $primary-color;
$login-form: $font-grey;
$login-title: $primary-color;
$login-highlight: $primary-dark;
$login-logo-height: 50px;



//Ranking Colors
$ranking-first: #ffd800;
$ranking-second: #b1b1b1;
$ranking-third: #ffa45c;

// Variables Exporting
:export {
  primaryColor: $primary-color;
  primaryLight: $primary-light;
  primaryDark: $primary-dark;
  secondary: $secondary;
  sistematic: $sistematic-gauges;
  success: $success-color;
  error: $error-color;
}

//Adds shadow and hover effect
@mixin withShadows {
  -webkit-box-shadow: 0px 0px 16px 0px $light-grey;
  -moz-box-shadow: 0px 0px 16px 0px $light-grey;
  box-shadow: 0px 0px 16px 0px $light-grey;
  transition: all 0.4s ease;

  &:hover {
    -webkit-box-shadow: 0px 5px 32px 0px $light-grey;
    -moz-box-shadow: 0px 5px 32px 0px $light-grey;
    box-shadow: 0px 5px 32px 0px $light-grey;
  }
}

@mixin rounded {
  border-radius: 15px;
}

@mixin withBorder {
  border: 1px solid $hover-grey;
}
/* V2 */


$greyish: #a4a4a4;
$greyish-brown: #474747;
$black-two: #111111;
$dusty-orange: #eb674e;
$pale-grey: #f3f5f9;
$cloudy-blue: #bbc4d3;
$dark-slate-blue: #23426e;
$dusk-blue: #1f618a;
$blueberry: #36417b;
$light-navy: #144f74;
$brownish-orange: #d37420;
$pinkish-red: #eb1a3c;
$pine: #383b2a;
$brownish-grey: #686767;
$black-three: #0f0f0f;
$mid-blue: #1d71b3;
$bluey-grey: #a8b0be;
$tealish: #31c2d3;
$warm-grey: #7a7a7a;
$ugly-blue: #336686;
$dark-slate-blue-two: #224672;
$duck-egg-blue: #dce8f8;
$dark-slate-blue-three: #243c69;
$grey-blue: #687d91;
$pinkish-grey: #c1c1c1;

/* SecondaryNavbar */
$secondary-navbar-bg-color-from: #262d5b;
$secondary-navbar-bg-color-to: #1f618b;
$secondary-navbar-active-bg-color: #0070d4;
$secondary-navbar-color: #c0c0c0;


/* search */
$search-client-main: #2d6f9c;
$search-client-main-alt: #84a8c4;
$search-client-bg: #f2f5fa;
$search-no-client-main: #5b4aaa;
$search-no-client-main-alt: #a29ac6;
$search-no-client-bg: #efecf9;
