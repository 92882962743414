@import "../../styles/variables.scss";

.mainNavbar {
  background: $navbar;
  background-size: cover;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 1000;
  @include withBorder;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  flex: 1;
  padding: 0 15px;
}

.brand {
  padding: 20px;
  padding-left: 0;
  display: block;

  img {
    height: $main-navbar-logo-height;
    display: block;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.item {
  padding: 20px 0 20px 20px;
}

.avatarContainer {
  img {
    font-size: 25px;
  }

  :global {
    .ui.dropdown {
      display: flex;
      align-items: center !important;
    }
  }
}
