@import "../../styles/variables";
@import "../../styles/animations/moveDown";

.sidebar {
  background-color: $white;
  min-height: 100%;
  position: relative;
  padding-top: 15px;
  @include withBorder;
  border-left: none;
}

.section {
  padding: 15px 0;
  margin: 0 20px;

  & + & {
    border-top: solid 1px $hover-grey;
  }

  &.noPadded {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.segment {
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
}

.segmentTitle {
  letter-spacing: 0.5px;
  font-size: 11px;
  margin-bottom: 6px;
  line-height: 1;
  color: #d0d0d0;
  text-transform: uppercase;
  font-weight: 600;
}

.segmentValue {
  font-size: 13px;
  line-height: 1.33;
  text-align: left;
  color: $font-grey;
  margin-bottom: 8px;
}

.signature {
  position: relative;
  text-align: center;
  margin: 0 20px 10px;

  img {
    display: block;
    margin: auto;
    max-height: 85px;
    mix-blend-mode: multiply;
    width: 100%;
  }

  &.noSignature {
    min-height: 115px;
  }
}

.fullName {
  text-transform: capitalize;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.21;
  color: $font-grey;
  margin-bottom: 20px;
  padding: 0 30px;
}

.age {
  font-weight: normal !important;
  font-size: 12px !important;
}

.socialNetworks {
  margin: 20px 35px;
  padding: 3px 1.5rem;
  border-radius: 50px;
  @include withBorder;

  :global {
    .row {
      padding-bottom: 0 !important;
      padding-top: 10px !important;
    }

    .ui.grid {
      margin: 0 !important;
    }

    .ui.grid > .row {
      padding: 0 !important;
      align-items: center;
    }

    .ui.grid > .row > svg {
      vertical-align: middle;
    }
  }
}

.socialColumn {
  text-align: center;
}

.socialIcon {
  fill: $primary-color;
  vertical-align: middle;
}

.socialIconTwitter {
  fill: $twitter-color;
}

.socialIconFacebook {
  fill: $facebook-color;
}

.socialIconWhatsapp {
  fill: $whatsapp-color;
}

.qrcodeIcon {
  cursor: pointer;
  fill: $primary-color;

  & svg {
    vertical-align: middle;
  }
}

.riskTitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #000000;
}

.uploadSignature {
  background: $hover-grey;
  color: $font-grey;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  fill: $font-grey;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: absolute;
  top: 0;
  font-size: 13px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;

  .signature:hover &, .noSignature & {
    opacity: 1;
    visibility: visible;
  }

  .noSignature & {
    border-radius: 5px;
  }
}

.uploadSignatureInput {
  position: fixed;
  top: -100em;
}

.phoneIcon {
  cursor: pointer;
}

.disabledIcon {
  cursor: not-allowed;
  opacity: 0.3;
}

.phones {
  list-style: none;
  margin: 0;
  padding: 0;
}

.phone {}

.riskData {
  align-items: center;
  display: flex !important;
  font-size: 18px;
  justify-content: center;
  padding-right: 0 !important;
}

.creditRiskLow {
  color: #048d6e;
  font-weight: bold;
}

.creditRiskMid {
  color: #000000;
  font-weight: bold;
}

.creditRiskHigh {
  color: #cc3f38;
  font-weight: bold;
}

.uploadAvatarButtons {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.waitingForImage {
  bottom: 0;
  filter: drop-shadow( 0px 0px 5px #000 );
  fill: #ffffff;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;

  img {
    position: absolute;
    height: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    z-index: 666;
  }
}

.cover {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.15);
}

.uploadAvatarButtonsFirst {
  border-right: 1px solid $font-grey;
  margin-right: 10px;
  padding-right: 10px;

  label {
    display: block;
    cursor: pointer;
    transition: fill 200ms ease;

    &:hover {
      fill: $primary-color !important;
      transition: fill 200ms ease;
    }
  }
}

.uploadAvatarButtonsSecond {
  display: block;
  cursor: pointer;
  transition: fill 200ms ease;

  &:hover {
    fill: $primary-color !important;
    transition: fill 200ms ease;
  }
}
