@import "../../styles/variables.scss";

.card {
    border-radius: 18px !important;
}

.value {
    font-size: 27px;
    line-height: 92px;
    color: $primary-color;
    font-weight: bolder;
}