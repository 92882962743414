@import "../../styles/variables.scss";

.pageWrapper {
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-height: 905px;
  padding-bottom: 100px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.eventsContainer {
  @include rounded;
  @include withBorder;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
}

.funnelBranchesContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  transition: margin-top 200ms ease;

  &.noMargin {
    margin-top: -40px;
    transition: margin-top 200ms ease;
  }
}

.boxContainer {
  overflow: hidden;
  position: relative;
  z-index: 1001;

  &:nth-child(1) {

    &:not(.maximized) {
      margin-right: 8px;
    }
  }

  &:nth-child(2) {
    &:not(.maximized) {
      margin-left: 8px;
    }
  }

}

.boxTitles {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 40px;

  .boxTitle {
    color: $primary-color;
    font-size: 13px;
    font-weight: 600;
    padding-left: 3px;
  }

  .boxTitleLink {
    align-items: center;
    color: #8c8c8c;
    cursor: pointer;
    display: flex;
    fill: #8c8c8c;
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
    outline: none;
    transition: color 200ms ease, fill 200ms ease;

    svg {
      margin-left: 5px;
    }

    &:hover {
      color: #515151;
      fill: #515151;
      transition: color 200ms ease, fill 200ms ease;
    }
  }
}

.whiteBox {
  border-radius: 15px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 600px;

  &.maximized {
    height: auto;
    padding: 20px;
  }
}

.dashboardFunnelContainer {
  padding: 20px;
  overflow-y: auto;
}
