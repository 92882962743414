@import "../../styles/variables.scss";

.box {
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #dfdfdf;
  padding: 10px;
  height: 100%;
}

.card {
  border-radius: 18px !important;
  padding: 12px 24px;
  margin-bottom: 32px;
}

.value {
  font-size: 27px;
  line-height: 60px;
  font-weight: bolder;
  color: $primary-color;    
}

.legends {
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.legend {
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 0;
  cursor: pointer;
}

.legendActive {
  color: #196cb4;
}

.legendColor {
  border-radius: 50%;
  display: inline-block;
  width: 11px;
  height: 11px;
}

.chart {
  margin: auto;
}
