@import "../../../../../../styles/variables.scss";

.rightColumn {
    align-items: flex-end;

    .priceCard {
        display: flex;
        flex-direction: column;
        
        .label {
            color: $font-grey;
        }
    
        .price {
            font-size: 2.5rem;
            line-height: 2.5rem;
            color: $primary-color;
            font-weight: bolder;
        }

        .totalAmount {
            margin-top: 8px;
            font-size: 2rem;
            line-height: 2rem;
            color: $primary-color;
            font-weight: bolder; 
        }
    }
    
    .button {
        display: flex;
        flex: 1;
        width: 100%;
    }
}

.card {
    margin-bottom: 16px !important;
}

.sliders {
    margin-top: 16px;
}

