@import "../../../../../../styles/variables.scss";

.priceCard {
    display: flex;
    flex-direction: column;
    
    .label {
        color: $font-grey;
    }

    .price {
        font-size: 2.5rem;
        line-height: 2.5rem;
        color: $primary-color;
        font-weight: bolder;
    }

    .totalAmount {
        margin-top: 8px;
        font-size: 2rem;
        line-height: 2rem;
        color: $primary-color;
        font-weight: bolder; 
    }
}

.button {
    display: flex;
    flex: 1;
    width: 100%;
}

.card {
    margin-bottom: 16px !important;
}

.sliders {
    margin-top: 16px;
}

.stepContainer {
    padding: 32px 24px;
}

.label {
    margin: 32px 0 16px 0;

    &:first-child {
        margin-top: 0;
    }
}

.premiumGrid {
    display: flex;
    flex: 1;
    justify-content: space-around;
}

.premium {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    cursor: pointer;
    @include withBorder;
    @include withShadows;

    &:global(.selected) {
        border: 4px $primary-color solid;
    }

    .header {
        display: flex;
        flex: 1;
        color: white;
        justify-content: center;
        padding: 1rem;
        background: $primary-color;
        align-items: center;
        font-weight: bold;
        flex-direction: column;

        .price {
            font-weight: normal;
        }

        &:global(.premium) {
            background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
        }
    }

    .group {
        padding: 1rem;
        color: $font-grey;

        .title {
            font-weight: bold;
        }
    }
}