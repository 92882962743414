@import "../../styles/variables";

.field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 5px;
  max-width: 100%;

  &.fieldSmall {
    margin-bottom: 0;
    padding: 0 8px;
  }

  &.alignTop {
    align-items: flex-start;
    margin-top: 20px;
  }
}

.fieldName {
  white-space: nowrap;
  font-size: 13px;
  color: $font-grey;
  margin-right: 15px;
  font-weight: 600;
  padding-bottom: 5px;
  min-width: 120px;

  .fieldSmall & {
    font-size: 11.2px;
    font-weight: bold;
    color: #3f2e92;
    text-transform: uppercase;
  }
}

.fieldValue {
  flex: 1;
  font-size: 13px;
  color: $font-grey;
  border-bottom: 2px solid $hover-grey;
  padding: 5px 10px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;

  &.noBorder {
    border-bottom: none;
  }

  &.wrap {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space: normal;
    text-overflow: initial;
  }
}

.innerPageGroup + .innerPageGroup {
  margin-top: 25px;
}

.innerPageSubtitle {
  font-size: 11.2px;
  font-weight: bold;
  line-height: 2.21;
  color: #3f2e92;
  text-transform: uppercase;
  padding-left: 5px;
  margin-bottom: 5px;
}

.customerFieldSubContainer {
  @include rounded;
  background: $hover-grey;
  margin-bottom: 10px;
  padding: 11px 15px;
}
