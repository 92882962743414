@import "../../styles/variables.scss";
@import "../../styles/animations/fadein";

$min-width: 600px;

.signInContainer {
  background-image: url(./images/login_background.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media (max-width: $min-width) {
    justify-content: flex-start;
  }
}

.n5Logo {
  img {
    height: 50px;
  }
}

.credicorpLogo {

  img {
    height: $login-logo-height;

    @media (max-width: $min-width) {
      height: 30px;
    }
  }
}

.rightWrapper {
  background: transparentize($login-bg, 0.15);
  padding: 5rem 8rem;
  position: relative;
  width: 50%;
  display: flex;
  align-items: space-between;
  flex-direction: column;

  @media (max-width: $min-width) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

.formWrapper {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: $min-width) {
    margin-top: 100px;
    position: relative;
    top: 0;
    transform: translate(0, 0);
    width: 100%;
  }

  h1 {
    font-family: 'Playfair Display';
    color: $login-title;
    font-weight: 300;
    font-size: 2.5rem;
    line-height: 1.6;

    @media (max-width: $min-width) {
      font-size: 30px;
    }

    span {
      color: $login-highlight;
    }
  }
}

.signInErrors {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparentize(#ff0000, 0.7);
  border-radius: 10px;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 10px;
}

.buttonContainer {
  @media (max-width: $min-width) {
    text-align: center;
  }
}

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/

  &.one {
    animation-delay: .1s;
  }

  &.two {
    animation-delay: .2s;
  }

  &.three {
    animation-delay: .4s;
  }

  &.four {
    animation-delay: .6s;
  }
}
