.waitingForImage {
  bottom: 0;
  filter: drop-shadow(0px 0px 5px #000);
  fill: #ffffff;
  height: 24px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 24px;
  left: 50%;
  top: 50%;
    
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}