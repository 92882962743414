@import "../styles/variables.scss";

.container {
  width: 42px;
  height: 42px;
  border: 2px solid white;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
}

.username {
  color: #666666 !important;
  margin-top: 0 !important;
  font-weight: normal !important;
  font-size: .8em !important;
  letter-spacing: 2px;
}

.wrapper :global(.ui.right.pointing.dropdown .menu:after) {
  right: 1.7em !important;
}

.avatarName {
  margin-left: 1rem;
  color: $font-grey;
}

.dropdownMenu {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  & > :global(.header) {
    background: $hover-grey !important;
    margin: 0 !important;
    padding: 10px !important;
  }

  & > :global(.divider) {
    margin: 0 !important;
  }

  & i {
    color: $primary-color;
  }

  &::after {
    display: none !important;
  }
}
