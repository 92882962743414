@import "../../styles/variables.scss";

.listItem {
  background: #ffffff;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 122px;
  padding: 15px;
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
  width: 112px;
  margin: 10px;

  &:hover,
  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 15px 1px;
    outline: none;
    transform: scale(1.1, 1.1);
    transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
  }
}

.listItemName {
  color: $font-grey;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
