@import "../../styles/variables.scss";

@mixin barFillAnimation($animation, $duration) {
  animation-name: $animation;
  animation-duration: $duration;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.resultsGraphBarWrapper {
  cursor: pointer;
  position: relative;
  transition: opacity 200ms ease;
  width: 90px;

  &.resultsGraphBarInactive {
    opacity: 0.1;
    transition: opacity 200ms ease;
  }

  &:hover {
    transform: scale(1.05);
    top: -3px;
  }
}

.barBackground {
  background: $hover-grey;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 47px;
}

.bar {
  align-items: center;
  background: #2e4976;
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 1px);
  width: 47px;

  @include barFillAnimation(barFill, 4s);

  &.overTheTopGoalMaxed {
    background: $success-color;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  &.overTheTopGoal {
    background: $success-color;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  &.aboveGoal {
    background: $warning-yellow;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  &.belowGoal {
    background: $warning-orange;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  &.criticalGoal {
    background: $error-color;
    flex-direction: column-reverse;
    padding-bottom: 5px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  &.criticalGoalMaxed {
    background: $error-color;
    flex-direction: column-reverse;
    padding-bottom: 5px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.barValue {
  color: #9197a3;
  height: 25px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: -22px;
  white-space: nowrap;
  width: 100px;

  &.barValueOutOfBox {
    top: -38px;
  }
}

.percentageNumber {
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  padding-top: 10px;
}

.percentageNumberReducedPadding {
  padding-top: 3px;
}

.percentageNumberOutOfBox {
  color: $font-grey;
  position: absolute;
  top: -30px;
}

.barLabelWrapper {
  line-height: 15px;
  font-size: 11px;
  text-transform: uppercase;
  padding-top: 10px;
  position: absolute;
  top: -120px;
  text-align: center;
  width: 100%;
}

.barLabel {
  display: block;
  margin: 0 auto;
  color: $font-grey;
}

.barLabelValue {
  display: block;
  font-size: 13px;


  @include barFillAnimation(barLabelsFill, 2s);
}

.barLabelTotal {
  color: $primary-color;
  display: block;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: -62px;
  width: 100%;

  @include barFillAnimation(barLabelsFill, 2s);
}

.weightContainer {
  bottom: -75px;
  color: $primary-color;
  font-size: 14px;
  text-align: center;
  position: absolute;
  width: 100%;

  @include barFillAnimation(barLabelsFill, 2s);
}

@keyframes barFill {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 500px;
  }
}

@keyframes barLabelsFill {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.previousValueTooltip {
  @include withBorder;
  @include rounded;
  color: $font-grey;
  background: white;
  font-size: 11px;
  opacity: 0;
  padding: 10px;
  position: absolute;
  left: 50%;
  top: -200vh;
  transform: translate(-50%, 0) scale(1) !important;
  transition: opacity 200ms ease;

  .resultsGraphBarWrapper:hover & {
    display: block;
    opacity: 1;
    transform: translate(-50%, 0) scale(1) !important;
    transition: opacity 200ms ease;
    top: -53px;
  }
}

.previousValueTooltipLine {
  white-space: nowrap;
}

.previousValueTooltipNumber {
  font-weight: bold;
}
