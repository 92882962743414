.avatar {
  border: solid 3px #ffffff;
  border-radius: 50%;
  display: block;
  font-size: inherit;
  height: 4em;
  overflow: hidden;
  position: relative;
  width: 4em;

  img {
    height: 4em;
    width: 4em;
  }
}

.noBorder {
  border: 0;
}