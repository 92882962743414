@import "../../../../../../../styles/variables.scss";

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.qrHolder {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 1rem 0;
    width: 100%;
}

.input {
    display: flex !important;
    margin-bottom: 8px !important;
    margin-right: 12px !important;
}

.label {
    display: flex !important;
    margin-bottom: 12px 0 !important;
}

.grid {
    display: flex !important;
    margin: 12px 0 !important;
}

.title {
    margin-bottom: 12px !important;
}

.fab {
    margin-right: 12px !important;

    &.whatsapp {
        background: $whatsapp-color;
        color: white;

        svg {
            fill: white !important;
        }
    }
}

.iconsHolder {
    margin-top: 12px;
}