@import "../../../../../../../styles/variables.scss";

.rangeSlider {
    margin-bottom: 16px;

    .label {
        font-size: 12px;
        color: $font-grey;
        margin-bottom: 12px;
    }
}