.beneficiaryForm {
    display: flex;
    margin: 1rem 0;
    flex-direction: column;

    .formGroup {
        margin-bottom: 12px;
    }

    .groupLabel {
        margin-bottom: 8px !important;           
    }
}